import React from 'react';
import axios from 'axios';
import Container from "./components/Container";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.apiClient = axios.create({
            baseURL: 'https://api.excellence.nu/v1/',
            auth: {
                username: localStorage.getItem('user'),
                password: localStorage.getItem('password')
            },
        });

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        today = yyyy + "-" + mm + "-" + dd;

        this.state = {
            stores: [],
            isLoggedIn: false,
            userName: '',
            password: '',
            token: '',
            date: today,
            connectionError: false,
            connectionErrorMessage: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getStats() {
        this.apiClient.get('adminstats?view=stores&date=' + this.state.date)
            .then(response => {
                this.setState({
                    isLoaded: true,
                    stores: response.data,
                    isLoggedIn: true,
                    connectionError: false,
                    connectionErrorMessage: ''
                });
            })
            .catch(error => {
                this.setState({
                    connectionError: true,
                    connectionErrorMessage: 'Ett fel inträffade när statistiken skulle hämtas'
                });
            });
    }

    componentDidMount() {
        // Hämta statistik
        // this.getStats();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        this.apiClient = axios.create({
            baseURL: 'https://api.excellence.nu/v1/',
            auth: {
                username: this.state.user,
                password: this.state.password
            },
        });

        this.apiClient.get('adminstats/')
            .then(response => {
                localStorage.setItem('user', this.state.user);
                localStorage.setItem('password', this.state.password);
                this.setState({
                    isLoggedIn: true
                });
                this.getStats();

                // Sätt en timer som hämtar statistik var 30 sekund
                setInterval(async () => {
                    this.getStats();
                }, 30000);
            })
            .catch(error => {
                this.setState({
                    connectionError: true,
                    connectionErrorMessage: 'Ett fel inträffade när statistiken skulle hämtas'
                });
            });
        event.preventDefault();
    }

    loginForm() {
        const style = {
            width: "400px",
            marginTop: "20px",
            background: "#f9fafd"
        }

        return (
            <div className="container" style={style}>
                <div className="row-heading">
                    <div className="col">
                        <h1>Logga in</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="mail">Användarnamn</label>
                                <input
                                    id="mail"
                                    name="user"
                                    type="text"
                                    inputMode="email"
                                    value={this.state.user}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    placeholder="E-postadress"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Lösenord</label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    placeholder="Lösenord"
                                />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-secondary btn-block" type="submit">Logga in</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        // Inte inloggad - skicka vidare till inloggningssidan
        if(this.state.isLoggedIn) {
            if(this.state.connectionError === false) {
                return <Container stores={this.state.stores} />
            } else {
                return <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <strong>{this.state.connectionErrorMessage}</strong>
                        </div>
                    </div>
                </div>
            }
        } else {
            return this.loginForm();
        }
    }
}

export default App;