import React from 'react';
import '../sass/custom.scss';


class Store extends React.Component {
    render() {
        let quantity = 0;
        let total = 0;

        this.props.stores.map(store => (
            quantity += store.quantity
        ));

        this.props.stores.map(store => (
            total += store.total
        ));

        return <div className="container">
            <div className="row-heading">
                <div className="col-sm-12"><h1>Försäljning per butik</h1></div>
            </div>
            <div className="row">
                <div className="col-3"><strong>Butik</strong></div>
                <div className="col-3 text-right"><strong>Antal</strong></div>
                <div className="col-3 text-right"><strong>Totalt</strong></div>
                <div className="col-3 text-right"><strong>Senast</strong></div>
            </div>
            <StoreRow stores={this.props.stores} />
            <TotalsRow quantity={quantity} total={total} />
        </div>
    }
}

class StoreRow extends React.Component {
    render() {
        return (
            this.props.stores.map(store => (
                <div className="row" key={store.id}>
                    <StoreColumn value={store.name} class="col-3" />
                    <StoreColumn value={new Intl.NumberFormat('sv-Sv').format(store.quantity)} class="col-3 text-right" />
                    <StoreColumn value={new Intl.NumberFormat('sv-Sv').format(store.total)} prefix={':-'} class="col-3 text-right" />
                    <StoreColumn value={store.latest} class="col-3 text-right" />
                </div>
            ))
        );
    }
}

class StoreColumn  extends React.Component {
    render() {
        return (
            <div className={this.props.class}><span>{this.props.value}{this.props.prefix}</span></div>
        );
    }
}

class TotalsRow extends React.Component {
    render() {
        return (
            <div>
                <div className="row-heading">
                    <div className="col-3">&nbsp;</div>
                    <div className="col-3 text-right">{new Intl.NumberFormat('sv-Sv').format(this.props.quantity)}</div>
                    <div className="col-3 text-right">{new Intl.NumberFormat('sv-Sv').format(this.props.total)}:-</div>
                    <div className="col-3">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-12">

                    </div>
                </div>
            </div>
        );
    }
}

export default Store;