import React from 'react';
import '../sass/custom.scss';
import Store from "./Store";
import { Redirect } from "react-router-dom";


class Container extends React.Component {
	render() {
		return (
			<div>
				{<Navbar />}
				{<Store stores={this.props.stores} />}
			</div>
		);
	}
}

class Navbar extends React.Component {
	logout() {
		localStorage.clear();
		return <Redirect to='/' />
	}

	render() {
		return (
			<nav className="navbar navbar-light navbar-expand-md navigation-clean">
				<div className="container"><h1>eXcellence statistik</h1>
					<button data-toggle="collapse" className="navbar-toggler" data-target="#navcol-1"><span
						className="sr-only">Toggle navigation</span><span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navcol-1">
						<ul className="nav navbar-nav ml-auto">
							<li className="nav-item"><a href="/" className="btn btn-link" onClick={this.logout}>Logga ut</a></li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

export default Container;